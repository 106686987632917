<script>
    import {DateTime} from "luxon";
    import {getContext} from "svelte";
    import api from "../api";

    // From Context
    const accessCode = getContext('accessCode');
    const portal = getContext('portal');

    export let callback = {
        date: '',
        time: ''
    }

    async function getCallbackSlots() {
        const slots = await api.autoAipCallbackSlots(portal, accessCode);
        return slots.map(slot => ({
            date: DateTime.fromISO(slot.date),
            description: slot.description,
            slots: slot.slots.map(s => ({
                ...s,
                time: {hour: parseInt(s.start.split(':')[0]), minute: parseInt(s.start.split(':')[1]), seconds: 0}
            }))
        }));
    }

    function getSlotsForDate(slots, date) {
        const x = slots.find(slot => slot.date === date);
        return x ? x.slots : [];
    }
</script>

<div class="callback">
    {#await getCallbackSlots()}
        <div></div>
    {:then slots}
        <div class="select-style">
            <select bind:value={callback.date} on:change={() => callback.time = ''}>
                <option value="" disabled selected>Day</option>
                {#each slots as {date, description}}
                    <option value={date}>{description}</option>
                {/each}
            </select>
        </div>
        <div class="select-style">
            <select bind:value={callback.time} disabled={!callback.date}>
                <option value="" disabled selected>Time</option>
                {#each getSlotsForDate(slots, callback.date) as {time, description, remaining}}
                    <option value={time} disabled={remaining <= 0}>{description}</option>
                {/each}
            </select>
        </div>
    {/await}
</div>

<style>

    .callback {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        align-items: stretch;
        justify-content: space-evenly;
    }

    .select-style {
        width: 100%;
        flex-grow: 1;
        padding: 0;
        margin: 0;
        border: 2px solid #ccc;
        border-radius: 5px;
        overflow: hidden;
    }

    .select-style:hover {
        border: 2px solid #348f96;
    }

    .select-style select {
        width: 100%;
        text-align: center;
        font-size: 1rem;
        padding: 8px;
        border: none;
        box-shadow: none;
        -webkit-appearance: none;
    }

    .select-style select:focus {
        outline: none;
    }

</style>
