import {get} from "svelte/store";
import {signOut, activeCase, activePortal} from "./store";
import {aipAccessCode} from "./utils";

let token = null;
let customerName = null;
let accountId = null;
let caseId = null;
let caseReference = null;

export default {

  // Set local vars
  setToken(token) {
    this.token = token;
  },
  setCustomerName(name) {
    this.customerName = name;
  },
  setAccountId(accountId) {
    this.accountId = accountId;
  },
  setCaseId(caseId) {
    this.caseId = caseId;
  },
  setCaseReference(caseReference) {
    this.caseReference = caseReference;
  },

  //
  // Request
  //
  async authedRequest(url, opts = {}, defaultFn = () => null, ignoreAuthenticationError = false) {
    opts.headers = opts.headers || {};
    opts.headers.Authorization = `Bearer ${this.token}`;
    const result = await fetch(url, opts);
    if (result.status === 401) {
        if (ignoreAuthenticationError === false) {
          signOut();
        }
      return defaultFn();
    }
    if (result.status === 403 && defaultFn !== null) {
      return defaultFn();
    }
    try {
      return await result.json();
    } catch {
      return defaultFn();
    }
  },

  //
  // Portals
  //
  async portals() {
    let portals = [];
    try {
      let result = await fetch("/api/portals", {
        method: "GET"
      });
      if (result.status === 200) {
        portals = await result.json();
      }
    }
    catch (e) {
      //console.log(e);
    }
    return portals;
  },

  //
  // Account
  //
  async register(email, password, captcha) {
    try {
      const result = await fetch("/api/create-account", {
        method: "POST",
        body: JSON.stringify({emailAddress: email, password, captcha})
      });
      if (result.status === 200) {
        return await result.json();
      } else {
        return {success: false, error: "Unable to register right now. Please try again later"};
      }
    } catch {
      return {success: false, error: "Unable to register right now. Please try again later"};
    }
  },
  async verify(token) {
    try {
      const result = await fetch(`/api/verify/${token}`, {method: "POST"});
      if (result.status === 200) {
        return await result.json();
      } else {
        return {success: false, error: "Unable to verify your e-mail address right now. Please try again later"};
      }
    } catch {
      return {success: false, error: "Unable to verify your e-mail address right now. Please try again later"};
    }
  },
  async signIn(email, password, captcha) {
    try {
      const result = await fetch("/api/sign-in", {
        method: "POST",
        body: JSON.stringify({emailAddress: email, password, captcha})
      });
      if (result.status === 200) {
        return await result.json();
      } else {
        return {success: false, error: "Unable to sign in right now. Please try again later"};
      }
    } catch {
      return {success: false, error: "Unable to sign in right now. Please try again later"};
    }
  },
  async reset(emailAddress, captcha) {
    try {
      const result = await fetch("/api/reset-password", {
        method: "POST",
        body: JSON.stringify({emailAddress, captcha})
      });
      if (result.status === 200) {
        return {success: true};
      } else {
        return {success: false, error: "Unable to reset your account right now. Please try again later"};
      }
    } catch {
      return {success: false, error: "Unable to sign in right now. Please try again later"};
    }
  },
  async completeReset(token, password, captcha) {
    try {
      const result = await fetch(`/api/reset-password/${token}`, {
        method: "POST",
        body: JSON.stringify({password, captcha})
      });
      if (result.status === 200) {
        return await result.json();
      } else {
        return {success: false, error: "Unable to reset your account right now. Please try again later"};
      }
    } catch {
      return {success: false, error: "Unable to reset your account right now. Please try again later"};
    }
  },

  //
  // API Specific
  //

  protection() {
    return this.authedRequest(`/api/${get(activeCase).portal.api}/${this.accountId}/protection/${this.caseReference}`, {}, () => [], true);
  },
  needs() {
    return this.authedRequest(`/api/${get(activeCase).portal.api}/${this.accountId}/case/${this.caseId}/needs`, {}, () => []);
  },
  needCompleted(needId, documents) {
    return this.authedRequest(`/api/${get(activeCase).portal.api}/${this.accountId}/case/${this.caseId}/needs/${needId}`,
      {
        method: "POST",
        body: JSON.stringify({documents: documents})
      });
  },
  documents(outbound) {
    return this.authedRequest(`/api/${get(activeCase).portal.api}/${this.accountId}/case/${this.caseId}/documents/${outbound ? "outbound" : "inbound"}`, {}, () => []);
  },
  smartSearch(id, payload) {
    return this.authedRequest(`/api/${get(activeCase).portal.api}/${this.accountId}/case/${this.caseId}/smart-search/${id}`,
      {
        method: "POST",
        body: JSON.stringify(payload)
      });
  },
  returnedFromSmartSearch(id) {
    return this.authedRequest(`/api/${get(activeCase).portal.api}/${this.accountId}/case/${this.caseId}/smart-search/${id}`,
      {
        method: "PUT"
      });
  },
  async eSignByCode(documentCode, signerCode) {
    try {
      const result = await fetch(`/api/${get(activeCase).portal.api}/e-sign/${documentCode}/${signerCode}`);
      if (result.status === 200) {
        const redirect = await result.json();
        return {redirect, success: true};
      } else {
        let error;
        try {
          const detail = await result.json();
          error = detail.error || "Unexpected error";
        } catch {
          error = "Unexpected error";
        }
        return {success: false, error};
      }
    } catch {
      return {success: false, error: "Unexpected error"};
    }
  },
  proceedToSign(packId) {
    return this.authedRequest(`/api/${get(activeCase).portal.api}/documents/e-sign/${packId}`, {method: "POST"});
  },
  upload({file, name, progress}) {
    return new Promise(resolve => {
      const xhr = new XMLHttpRequest();
      xhr.upload.addEventListener("loadstart", progress);
      xhr.upload.addEventListener("progress", progress);
      xhr.upload.addEventListener("load", progress);
      xhr.upload.addEventListener("error", progress);
      xhr.addEventListener("load", async () => {
        if (xhr.status === 200) {
          resolve({success: true, doc: JSON.parse(xhr.responseText)});
          return;
        } else if (xhr.status === 401) {
          signOut();
        }
        resolve({success: false});
      });
      xhr.addEventListener("error", () => resolve({success: false}));
      xhr.open("POST", `/api/${get(activeCase).portal.api}/${this.accountId}/case/${this.caseId}/documents?fileName=${name || file.name}`, true);
      xhr.setRequestHeader("Authorization", `Bearer ${this.token}`);
      xhr.setRequestHeader("Content-Type", file.type);
      xhr.send(file);
    });
  },

  //
  // Auto AIP
  //
  async autoAipStatus(portal, accessCode) {
    const result = await fetch(`/api/${portal}/quote/${accessCode}`);
    if (result.ok) {
      let detail = await result.json();
      detail = this.indexQuotes(detail);
      return {...detail, success: true};
    }
    return {success: false, error: "This link has expired. Please contact us to obtain a new one."};
  },

  async autoAipUpdate(portal, accessCode, status, payload, method = "POST") {
    const result = await fetch(`/api/${portal}/quote/${accessCode}/${status}`, {
      method: method,
      body: payload ? JSON.stringify(payload) : null
    });
    if (result.ok) {
      let detail = await result.json();
      detail = this.indexQuotes(detail);
      return {...detail, success: true};
    }
    return {success: false, error: "This link has expired. Please contact us to obtain a new one."};
  },

  async autoAipUpdateQuote(portal, accessCode, payload) {
    const result = await fetch(`/api/${portal}/quote/${accessCode}/quote`, {
      method: "PUT",
      body: payload ? JSON.stringify(payload) : null
    });
    if (result.ok) {
      let detail = await result.json();
      detail = this.indexQuotes(detail);
      return {...detail, success: true};
    }
    return {success: false, error: "This link has expired. Please contact us to obtain a new one."};
  },

  indexQuotes(quote) {
    if (!quote.quotes) return quote;
    let index = -1;
    quote.quotes = quote.quotes.map ( q => {
      index++;
      let pIndex = -1;
      return {
        ...q,
        products: q.products.map ( p => {
          pIndex++;
          return {
            ...p,
            index: pIndex,
            quoteIndex: index
          }
        })
      }
    })
    return quote
  },

  async autoAipProductTransferESign(portal, accessCode) {
    const result = await fetch(`/api/${portal}/quote/${accessCode}/esign`, {
      method: "POST"
    });
    if (result.ok) {
      let detail = await result.json();
      return {...detail, success: true};
    }
    return {success: false, error: "An error occurred. Please contact us."};
  },

  autoAipUpload({portal, accessCode, uploadType, file, name, progress}) {
    return new Promise(resolve => {
      const xhr = new XMLHttpRequest();
      xhr.upload.addEventListener("loadstart", progress);
      xhr.upload.addEventListener("progress", progress);
      xhr.upload.addEventListener("load", progress);
      xhr.upload.addEventListener("error", progress);
      xhr.addEventListener("load", async () => {
        if (xhr.status === 200) {
          resolve({success: true, doc: JSON.parse(xhr.responseText)});
          return;
        } else if (xhr.status === 401) {
          signOut();
        }
        resolve({success: false});
      });
      xhr.addEventListener("error", () => resolve({succcess: false}));
      xhr.open("POST", `/api/${portal}/quote/${accessCode}/${uploadType}?fileName=${name || file.name}`, true);
      xhr.setRequestHeader("Content-Type", file.type);
      xhr.send(file);
    });
  },

  async autoAipPageView(portal, accessCode) {
    await fetch(`/api/${portal}/quote/${accessCode}/view`, {
      method: "POST"
    })
  },

  async autoAipCallbackSlots(portal, accessCode) {
    const result =
        await fetch(`/api/${portal}/quote/${accessCode}/callback`);
    if (result.ok) {
      return await result.json();
    }
    return [];
  },

  async autoAipCallback(portal, accessCode, time) {
    const result =
        await fetch(`/api/${portal}/quote/${accessCode}/callback`, {
          method: "POST",
          body: JSON.stringify({time})
        });
    if (result.ok) {
      let detail = await result.json();
      return {...detail, success: true};
    }
    return {success: false, error: "We couldn't set your callback at this time. Please try again later."};
  },

  async autoAipThirdPartyConsent(portal, accessCode) {
    const result = await fetch(`/api/${portal}/quote/${accessCode}/third-party-consent`, {
      method: "POST"
    });
    if (result.ok) {
      let detail = await result.json();
      return {...detail, success: true};
    }
    return {success: false, error: "An unexpected error occurred."};
  },

  //
  // AIP
  //
  async aipStatus(portal, accessCode) {
    const result = await fetch(`/api/${portal}/aip/${accessCode}`);
    if (result.ok) {
      const detail = await result.json();
      return {...detail, success: true};
    }
    return {success: false, error: "This link has expired. Please contact us to obtain a new one."};
  },
  async expenditureResult(responseId) {
    const magicToken = aipAccessCode();
    await fetch(`/api/${get(activePortal).api}/aip/${magicToken}/expenditure-questionnaire`, {
      method: "POST",
      body: JSON.stringify({responseId})
    })
  },
  async skipIdUpload() {
    const magicToken = aipAccessCode();
    await fetch(`/api/${get(activePortal).api}/aip/${magicToken}/identity-document/skip`, {
      method: "POST"
    })
  },
  aipUpload({accessCode, uploadType, file, name, progress}) {
    return new Promise(resolve => {
      const xhr = new XMLHttpRequest();
      xhr.upload.addEventListener("loadstart", progress);
      xhr.upload.addEventListener("progress", progress);
      xhr.upload.addEventListener("load", progress);
      xhr.upload.addEventListener("error", progress);
      xhr.addEventListener("load", async () => {
        if (xhr.status === 200) {
          resolve({success: true, doc: JSON.parse(xhr.responseText)});
          return;
        } else if (xhr.status === 401) {
          signOut();
        }
        resolve({success: false});
      });
      xhr.addEventListener("error", () => resolve({succcess: false}));
      xhr.open("POST", `/api/${get(activePortal).api}/aip/${accessCode}/${uploadType}?fileName=${name || file.name}`, true);
      xhr.setRequestHeader("Content-Type", file.type);
      xhr.send(file);
    });
  },
  async requestCallback(callbackDateTime) {
    const magicToken = aipAccessCode();
    await fetch(`/api/${get(activePortal).api}/aip/${magicToken}/request-callback`, {
      method: "POST",
      body: JSON.stringify({when: callbackDateTime})
    })
  },
  async aipPageView() {
    const magicToken = aipAccessCode();
    await fetch(`/api/${get(activePortal).api}/aip/${magicToken}/view`, {
      method: "POST"
    })
  },

  //
  // Verification
  //
  async getVerificationOptions() {
    return this.authedRequest("/api/verify", {}, () => []);
  },

  async requestCode(channel, tag) {
    return this.authedRequest("/api/verify",
      {
        method: "POST",
        body: JSON.stringify({ channel, tag })
      });
  },

  async checkCode(code, tag) {
    return this.authedRequest("/api/verify/check",
      {
        method: "POST",
        body: JSON.stringify({code, tag})
      });
  },
}
